import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
    // order and from where user language should be detected
    order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag',  'subdomain'],

        // keys or params to lookup language from
        lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' }
}
const resources = {
    ua: {
        translation: {
            'mainWrapMenu.HeaderProfile':'Ваш профіль',
            'mainWrapMenu.Profile': 'Профіль',
            'mainWrapMenu.Dashboard': 'Панель',
            'mainWrapMenu.Logout':'Вихід',
            "login.signin": "Ввійти",
            'login.signinLabel': "Вхід",
            'login.password': "Пароль",
            'login.login': 'Логін(формат +380..)',
            'login.email': 'E-mail',
            'login.forgot':"Забули пароль?..",
            'reg.reset_password': 'Скинути пароль',
            'reg.email': 'E-mail',
            'reg.phone': 'Телефон', 
            'profile.generalTabInfomation':'Основна інформація',
            'profile.additionalInfo': 'Документи',
            'profile.facultiesAndSpec': 'Факультети та спеціальність',
            'profile.name': 'І\'мя',
            'profile.lastName': 'Прізвище',
            'profile.middleName': 'По батькові',
            'profile.Birthday': 'Дата народження',
            'profile.phone': 'Телефон',
            'profile.email': 'E-mail',
            'profile.IPN': 'ІПН',
            'profile.document' :'Документ',
            'profile.documentInfoSeries' :'Документ серія',
            'profile.documentInfoNumber' :'Документ номер',
            'profile.documentInfoDateGet' :'Документ видано (дата)',
            'profile.documentInfoIssued' :'Документ видано',
            'profile.documentInfoExpiredDate' :'Документ дійсний до',
            'profile.uploadNewPhoto': 'Фото', 
            'profile.upload_photo':'Завантажити фото',
            'profile.student_ticket':'Студенський квиток',
            'profile.student_ticket_id': 'Серія, номер',
            'profile.student_ticket_date_issued': 'Видано',
            'profile.student_ticket_date_expired' :'Дійсний до',
            'profile.student_ticket_facultet_spec' :'Факультет (відділення), структурний підрозділ, форма навчання',
            'profile.student_ticket_group': 'Група',
            'form.submit': 'Відправити',
            'form.close': 'Закрити',
            'setPass.setPassword': 'Встановити новий пароль',
            'setPass.putYouPasswordHere': 'Новий пароль',
            'setPass.repeatPassword':'Повторіть пароль',
            'setPass.setYourPassMsg': 'створіть новий пароль. Довжина паролю не менше 8 символів. Пароль має складатись з букв англ алфвавіту та цифр.',
            'setPass.resetPassSubmitButton': 'Зберегти',
            'setPass.minLength8DgitiLetters': 'Довжина паролю має бути не манше 8 символів. Пароль: англ букви та цифри',
            'setPass.passwrodNotMatch': 'Паролі не співпадають',
            'setPass.errorLink': 'Користувача не знайдено або посилання не активне, бо ви встановили пароль',
            'setPass.baseLink': 'На головну',
            'text.noData': 'Відсутні данні',
            'text.groupe': 'група',
            'text.save': 'Зберегти',
            'text.go_home': 'На головну', 
            'error.page_not_found': 'Сторінку не знайдено',
            'error.page_404_description':'На жаль, ми не змогли знайти сторінку, яку ви шукали. Можливо, сторінка була видалена або перенесена в інше місце.',
            'error.unauthorized_access':'Неавторизований доступ',
            'error.page_401_description':'Ви не маєте дозволу на перегляд цієї сторінки. Будь ласка, авторизуйтесь, щоб отримати доступ до цієї сторінки.',
            'error.access_denied': 'Доступ заборонено',
            'error.page_403_description': 'Ви не маєте дозволу на перегляд цієї сторінки.',
            'error.server_error' : 'Помилка на сервері',
            'error.page_500_description' : 'На жаль, сталася помилка на сервері, тому ми не змогли відобразити цю сторінку. Наші розробники вже повідомлені про цю проблему та працюють над її вирішенням.',
            'reset.empty': 'поле пусте!',
            'reset.wrong_captcha':'капча не пройдена!',
            'reset.passwordOk': 'Користувача знайдено. перевірте ваш емейл',
            'reset.user_Not_found': 'Помилка! Користувача з таким емейлом не знайдено!',
            'reset.input_email': 'Введіть емейл!!!! ',
            'profile.MyRequestsList':'Мої запити',
            'profile.DateRequest': 'Дата зверення',
            'mainWrapMenu.requests': 'Запити',
            'mainWrapMenu.create': 'новий запит',
            'profile.ID': '№ запиту',
            'profile.FLMN':'ПІБ',
            'profile.templateName': 'назва шаблону',
            'profile.templateType': 'тип запиту',
            'profile.typeMessage':'Звернення',
            'profile.typeRequest': 'Заява',
            'profile.facultyName': 'факультет',
            'profile.adminDescription': 'Опис для деканата',
            'financial.no_reports_available': "Звіти відсутні",
            'financial.noDataAvailableForThisFaculty':"звіти відсутні для місця навчання",
            'mainWrapMenu.studyReport': 'оплата за навчання',
            'mainWrapMenu.financialReport': 'оплата за проживання',
            'profile.Links': 'Корисні посилання',
            'mainWrapMenu.Links': 'Посилання',
            'mainWrapMenu.CheckList': 'Шаблони обхідних листів',
            'message.payNotification': 'Заплатити {{sum}} грн. в СНАУ до {{date}}',
            'new': 'Новий',
            'activeReq': 'Прийнято на розгляд',
            'cancelledReq': 'Не прийнято на розгляд',
            'notApprovedReq':'Заяву не погоджено',
            'approvedReq': 'Заяву погоджено',
            'activeSimple': "Розглядається",
            'doneSimple':'Закрито' ,
            'profile.status': 'Статус',
            'profile.MyBypass': "Шаблони обхідних листів",
            'bypass.begin': "Розпочати",
            'bypass.back': "До списку",
             'bypass.new': "створено",
            'bypass.active':"в прогресі",
            'bypass.done': 'пройдено',
            'bypass.not': 'не розпочато',
            'bypass.fieldTableColumn': "Відділ",
            'bypass.fieldTablecomment': "Відмітка",
            'message.noCredits':"пройдено",
            'bypass.fieldTableColumnForPassing': "Відділи для проходження",
            'message.noData':"не розглянуто",
            'bypass.downloadPdf': "зберегти pdf",
            'bypass.statusNotExists': "ще не створено",
            'agrement.agreeApply': 'Підписати',
            'agrement.infoText': 'Вам необхідно підписати декларацію. Прочитайте уважно і, якщо згодні, натисніть підписати',
            'infoText.deactivate': "Підготуйте файл .csv де перша колонка ПІБ студента якого треба деактивувати, друга його ІПН, третя код вашого факультету (див коди нижче) \n з якого відраховано студента",
            'infoText.facultyCodes': "1 - Економіка і менеджмент, 2 - Ветеринарна медицина, 8 - Харчових технологій, 9 - Будівництва і транспорту, 10 - Агротехнологій та природо користування, 16 -інженерно-технологічний, 19 - Біолого-технологічний, 35 - Юридичний",
            'infoText.linkExample': 'якщо у вас виникли проблеми зі створенням файлу завантажте наступний файл і заповніть його:',
            'infoText.fileExample': 'Завантажити',
            'profile.groupFilter': "Група",
            'profile.dateBegin': 'Дата з',
            'profile.dateEnd': 'Дата до',
            'profile.showSchedule': 'отримати',
            'profile.addToCalender': 'додати в календар',
            'profile.loading': 'завантажуємо...',
            'profile.messageGroupScheduleExists': 'Вашу групу поки не додано в систему. Спробуйте пізніше',
            'mainWrapMenu.schedule': 'Розклад'
        }
    },
    en: {
        translation: {
            'mainWrapMenu.HeaderProfile':'Your Account',
            'mainWrapMenu.Profile': 'Account',
            'mainWrapMenu.Dashboard': 'Dashboard',
            'mainWrapMenu.Logout':'Logout',
            "login.signin": "Login",
            'login.signinLabel': "Login",
            'login.password': "Password",
            'login.login': 'Login (+380...)',
            'login.email': 'E-mail',
            'login.forgot':"Forgot my password",
            'reg.reset_password': 'Reset password',
            'reg.email': 'E-mail',
            'reg.phone': 'Phone number', 
            'profile.generalTabInfomation':'General Information',
            'profile.additionalInfo': 'Documents',
            'profile.facultiesAndSpec': 'Faculties and specialties',
            'profile.name': 'Name',
            'profile.lastName': 'Last Name',
            'profile.middleName': 'Middle Name',
            'profile.Birthday': 'Date of Birth',
            'profile.phone': 'Phone number',
            'profile.email': 'E-mail',
            'profile.IPN': 'Taxpayer identification number (IPN)',
            'profile.document' :'Document',
            'profile.documentInfoSeries' :'Document series',
            'profile.documentInfoNumber' :'Document number',
            'profile.documentInfoDateGet' :'Document issued (date)',
            'profile.documentInfoIssued' :'The document has been issued',
            'profile.documentInfoExpiredDate' :'The document is valid until',
            'profile.uploadNewPhoto': 'Upload photo', 
            'profile.upload_photo': 'Upload photo',
            'profile.student_ticket':'Student ticket',
            'profile.student_ticket_id': 'Series, number',
            'profile.student_ticket_date_issued': 'Issued',
            'profile.student_ticket_date_expired' :'Valid until',
            'profile.student_ticket_facultet_spec' :'Faculty (department), structural subdivision, form of education',
            'profile.student_ticket_group': 'Group',
            'form.submit': 'Submit',
            'form.close': 'Close',
            'setPass.setPassword': 'Set Password',
            'setPass.putYouPasswordHere': 'Enter new password',
            'setPass.repeatPassword':'Repeat password',
            'setPass.setYourPassMsg': 'Create a new password. The length of the password is at least 8 characters. The password must consist of letters of the English alphabet and numbers.',
            'setPass.resetPassSubmitButton': 'Save',
            'setPass.minLength8DgitiLetters': 'Password should be at least 8 characters',
            'setPass.passwrodNotMatch': 'Passwords do not match',
            'setPass.errorLink': 'User not found or link is not active because password already was set',
            'text.noData': 'Missing data',
            'text.groupe': 'group',
            'text.save': 'Save',
            'text.go_home': 'Home page',
            'setPass.baseLink': 'Go to home page',
            'error.page_not_found': 'Page not found',
            'error.page_404_description':'Oops, the page you are looking for cannot be found!',
            'error.unauthorized_access':'Unauthorized',
            'error.page_401_description':'Oops, you are not authorized to access this page!',
            'error.access_denied': 'Forbidden',
            'error.page_403_description': 'Oops, you are not allowed to access this page!',
            'error.server_error' : 'Internal Server Error',
            'error.page_500_description' : 'Oops, something went wrong on our end!',
            'reset.empty': 'field is empty!',
            'reset.wrong_captcha':'captcha not passed!',
            'reset.passwordOk': 'User found. check your email',
            'reset.user_Not_found': 'Error! No user with this email was found!',
            'reset.input_email': 'Enter your email!!!! ',
            'profile.MyRequestsList':'requests',
            'profile.DateRequest': 'date',
            'mainWrapMenu.requests': 'requests',
            'mainWrapMenu.create': 'new request',
            'profile.FLMN':'Name',
            'profile.templateName': 'request type',
            'profile.facultyName': 'faculty',
            'mainWrapMenu.studyReport': 'study payments',
            'mainWrapMenu.financialReport': 'rent payments',
            'mainWrapMenu.Links': 'Links',
            'message.payNotification': 'Pay {{sum}} to SNAU before {{date}}',
            'bypass.statusNotExists': "not exists",
            'agrement.agreeApply': 'I Agree',
            'agrement.infoText': 'Вам необхідно підписати декларацію. Прочитайте уважно і, якщо згодні, натисніть підписати',
        }
    }
};
i18n.use(Backend)

    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // init i18next init({
    //         ,
    //     })
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        detection: options,
        fallbackLng: 'ua',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
//
// // the translations
// // (tip move them in a JSON file and import them,
// // or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//     en: {
//         translation: {
//             "Welcome to React": "Welcome to React and react-i18next"
//         }
//     },
//     fr: {
//         translation: {
//             "Welcome to React": "Bienvenue à React et react-i18next"
//         }
//     }
// };
//
// i18n
//     .use(initReactI18next) // passes i18n down to react-i18next
//     .init({
//         resources,
//         lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//         // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//         // if you're using a language detector, do not define the lng option
//
//         interpolation: {
//             escapeValue: false // react already safes from xss
//         }
//     });
//
// export default i18n;