import React, {useEffect, useState} from "react";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import IconButton from '@mui/material/IconButton';
export const UploadData = (props)=>{
    const [file, setFile] = useState();
    const [dataLogResult, setDataLogResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadFileLink, setUploadFileLink] = useState(config.apiLinks.uploadUserFile);

    useEffect(()=>{
        if ( props.linkUpload ){
            setUploadFileLink(props.linkUpload)
        }
    }, [props.linkUpload])

    useEffect( ()=>{
            if (!props.isAllowFacultyAdmin){
                const check = async ()=>{
                    await axiosRun( config.apiLinks.checkUser, 'GET' );
                };
                check();
            }
    }, [] )

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = async () => {
        if (!file) {
            return;
        }
        try{
          setIsLoading(true);
          const data = await axiosRun(uploadFileLink, "POST", {file:file});
          setDataLogResult(data);
          setIsLoading(false);
        }catch (e) {

        }

    }
    return (
        <Paper sx={{p:3, minHeight: '85vh'}}>
            <Typography variant="h4" component="h1" gutterBottom>
            {props.title?props.title:"Загрузка студентів"}
            </Typography>
            {console.log('fhkjsdvds', file)}
            {isLoading===false&& Object.keys(dataLogResult).length === 0 &&<>
                <IconButton sx={{mr:3}} size="large" color="primary" aria-label="upload picture" component="label">
                  <DriveFolderUploadIcon/>
                    <input hidden type="file" onChange={handleFileChange}  />
                </IconButton>
                <Button variant="contained" component="label" onClick={handleUploadClick} disabled={file?false:true}>
                    Upload      
                </Button>
                <div>{file && `${file.name} - ${file.type}`}</div>
                {/* <input type="file" onChange={handleFileChange} />
                <div>{file && `${file.name} - ${file.type}`}</div>
                <button onClick={handleUploadClick}>Upload</button> */}
            </>}
            
            {isLoading===true&&"Loading data...."}
            {Object.keys(dataLogResult).length>0&&<div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Information about success import from file {`${file.name}`}:
                        </Typography>

                            <List dense={true}>
                                {dataLogResult.logs.map( (itemLog) =>{
                                  return  <ListItem>
                                      <div><pre>{JSON.stringify(itemLog, null, 2) }</pre></div>
                                    </ListItem>
                                }) }
                            </List>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Error during import from file {`${file.name}`}:
                        </Typography>
                            <List dense={true}>
                                {dataLogResult.errors.map( (itemError) =>{
                                   return <ListItem>
                                       <div><pre>{JSON.stringify(itemError, null, 2) }</pre></div>
                                    </ListItem>
                                }) }
                            </List>
                    </Grid>
                </Grid>
            </div>}
        </Paper>
    );
}

