import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Alert
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { axiosRun } from "../utils/axiosWrap";
import { config } from "../config";
import EventNoteIcon from '@mui/icons-material/EventNote';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {useTranslation} from "react-i18next";
import fileDownload from "js-file-download";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';


const ScheduleComponent = () => {
    // Стан для фільтрів
    const [group, setGroup] = useState('');
    const [groups, setGroups] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [scheduleData, setScheduleData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation( );

    useEffect( ()=>{
        const getData = async ()=> {
            setLoading(true);
            const userData = await axiosRun(config.apiLinks.getUserInfo, "GET");
            console.log("user data ", userData);
            setLoading(false);
            const _groups = [];
            userData.UserToFaculties.forEach( itm => {
                console.log("itm ", itm);
                if (itm.Group && itm.Group?.externalId ){
                    _groups.push({id: itm.Group?.externalId, name: itm.Group.name});
                }
            })
            setGroups(_groups);
            console.log("groups ", groups.length);

        }
        getData();
        return ()=>{}
    },[])

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // +1, бо місяці від 0 до 11
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Виконання POST-запиту до API
    const fetchSchedule = async () => {
        setLoading(true);
        try {
            const data = await axiosRun(config.apiLinks.getScheduleByGroupDate, "POST", {
                group,
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
            });
            setScheduleData(data.data || []); // Беремо поле data із відповіді
        } catch (error) {
            alert('Error fetching schedule');
        } finally {
            setLoading(false);
        }
    };

    const handleShowSchedule = () => {
        if (group && startDate && endDate) {
            setScheduleData([]); // Очищаємо старі дані
            fetchSchedule();
        } else {
            alert('Please select group and date range');
        }
    };

    const handleAddCalendar = async ()=>{
        const result = await axiosRun(config.apiLinks.getCalendar, "PUT", {schedule:scheduleData});
        fileDownload(result, 'schedule.ics');
    }

    // Унікальні номери пар і час початку
    const uniqueLessons = () => {
        const lessonMap = new Map();
        scheduleData.forEach(day => {
            day.lessons.forEach(lesson => {
                const key = `${lesson.number}-${lesson.periods[0].timeStart}`;
                if (!lessonMap.has(key)) {
                    lessonMap.set(key, {
                        number: lesson.number,
                        timeStart: lesson.periods[0].timeStart,
                    });
                }
            });
        });
        return Array.from(lessonMap.values()).sort((a, b) => a.number - b.number);
    };

    // Унікальні дати
    const dateColumns = scheduleData.map(day => day.date);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper sx={{ p: 3, m: 2 }}>
                <Typography
                    variant="h5"
                    component="h1"
                    gutterBottom
                    sx={{ fontWeight: 'bold' }}
                >
                    Розклад занять
                </Typography>
                {groups.length === 0 ? (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        {t("profile.messageGroupScheduleExists")}
                    </Alert>
                ) : (<Box>
                    {/* Фільтри */}
                    <Box sx={{ display: 'flex', width: { xs: '100%', sm: '100%', md: '50%', lg: '30%', xl: "30%" },gap: 1, mb: 3, flexDirection: 'column',  alignItems: 'left', maxWidth: "99%" }}>
                        <FormControl>
                            <InputLabel>{t("profile.groupFilter")}</InputLabel>
                            <Select
                                sx={{width: "100%"}}
                                value={group}
                                label={t("profile.groupFilter")}
                                onChange={(e) => {
                                    setGroup(e.target.value)
                                    setScheduleData([]);
                                }}
                            >
                                {groups?.map((groupItem) => (
                                    <MenuItem key={groupItem.id} value={groupItem.id}>
                                        {groupItem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <DatePicker
                            sx={{width: "100%"}}
                            label={t("profile.dateBegin")}
                            value={startDate}
                            onChange={(newValue) => {setStartDate(newValue); setScheduleData([]);}}
                            renderInput={(params) => <TextField {...params} />}
                        />

                        <DatePicker
                            label={t("profile.dateEnd")}
                            value={endDate}
                            onChange={(newValue) => { setEndDate(newValue); setScheduleData([]);}}
                            renderInput={(params) => <TextField {...params} />}
                        />

                        <Button
                            startIcon={<EventNoteIcon />}
                            variant="contained"
                            onClick={handleShowSchedule}
                            disabled={loading}
                        >
                            {loading ? t('profile.loading') : t('profile.showSchedule')}
                        </Button>
                        {scheduleData.length>0&&<Button
                            startIcon={<CalendarMonthIcon />}
                            variant="contained"
                            onClick={handleAddCalendar}
                            disabled={loading}
                        >
                            {loading ? t('profile.loading') : t('profile.addToCalender')}
                        </Button>}

                    </Box>

                    {/* Таблиця */}
                    {scheduleData.length > 0 && (
                        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', minWidth: 100 }}>Pair/Time</TableCell>
                                        {dateColumns.map((date) => (
                                            <TableCell key={date} sx={{ fontWeight: 'bold', minWidth: 200 }}>
                                                {new Date(date).toLocaleDateString('uk-UA', {
                                                    weekday: 'short',
                                                    day: 'numeric',
                                                    month: 'short',
                                                })}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {uniqueLessons().map((lesson) => (
                                        <TableRow key={`${lesson.number}-${lesson.timeStart}`}>
                                            <TableCell>
                                                <Typography variant="subtitle2">
                                                    {`Пара ${lesson.number} (${lesson.timeStart})`}
                                                </Typography>
                                            </TableCell>
                                            {dateColumns.map((date) => {
                                                const day = scheduleData.find(d => d.date === date);
                                                const lessonData = day?.lessons.find(l =>
                                                    l.number === lesson.number &&
                                                    l.periods[0].timeStart === lesson.timeStart
                                                );
                                                const period = lessonData?.periods[0];
                                                return (
                                                    <TableCell key={date}>
                                                        {period ? (
                                                            <Box>
                                                                <Typography variant="body2" color="primary">
                                                                    {period.disciplineShortName} ({period.typeStr})
                                                                </Typography>
                                                                <Typography variant="caption">
                                                                    {period.classroom} | {period.teachersName}
                                                                </Typography>
                                                                <Typography variant="caption" display="block">
                                                                    {period.timeStart}-{period.timeEnd}
                                                                </Typography>
                                                            </Box>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>)}
            </Paper>
        </LocalizationProvider>
    );
};

export default ScheduleComponent;