import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  Container,
  Box,
  IconButton,
  Typography,
  List,
  Breadcrumbs,
  styled,
  ListItemText,
  ListItemIcon,
  ListItem,
  Toolbar,
  Badge,
  Divider,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Outlet, Link, useLocation } from "react-router-dom";
import { Drawer } from "./UI/Drawer";
import { AppBar } from "./UI/AppBar";
import LogoutIcon from "@mui/icons-material/Logout";
import { shallowEqual, useSelector } from "react-redux";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import HistoryIcon from "@mui/icons-material/History";
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LinkIcon from '@mui/icons-material/Link';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ClearIcon from '@mui/icons-material/Clear';
export const MainWrapperAdmin = () => {
  const [open, setOpen] = useState(window.innerWidth <= 768 ? false : true);
  const user = useSelector((state) => state.users.user, shallowEqual);
  const gotUserInfo = useSelector((state) => state.users.gotUserInfo);
  const location = useLocation();

  const theme = createTheme({
    status: {
      danger: "#fff",
    },
    components: {
      MuiListItem: {
        styleOverrides: {
          button: {
            color: "#212b36",
          },

        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: "14px",
          },
        },
      },
    },
  });

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const MenuListItem = ({ linkTo, name, icon }) => (
    <ListItem
      selected={location.pathname === linkTo}
      component={Link}
      to={linkTo}
      button={true}
      sx={ {
        "&.Mui-selected": {
          backgroundColor: "#F1FFF6"
        }
      }}
    >
      <ListItemIcon >
        {icon}
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );

  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              backgroundColor: "#00A43C",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              // color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Administration
            </Typography>
            {/*<BreadcrumbsAdmin location={location.pathname} />*/}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {gotUserInfo && user.user && (
            <List component="nav" sx={{ pt: 0 }}>
              {/*{gotUserInfo && user.user.roles === "SUPER_ADMIN" && (*/}
              {/*  <ListItem*/}

              {/*    component={Link}*/}
              {/*    to={"/administration/upload/users/"}*/}
              {/*    button={true}*/}
              {/*  >*/}
              {/*    <ListItemIcon >*/}
              {/*      <RecentActorsIcon />*/}
              {/*    </ListItemIcon>*/}
              {/*    <ListItemText primary="New upload users from file" />*/}
              {/*  </ListItem>*/}
              {/*)}*/}
              {gotUserInfo && user.user.roles === "SUPER_ADMIN" && (
                <>
                  <MenuListItem
                      linkTo="/administration/admin/users/list"
                      name="Aдміністратори"
                      icon={<AdminPanelSettingsOutlinedIcon />}
                  />
                  <MenuListItem
                      linkTo="/administration/departments/list"
                      name="Відділи"
                      icon={<ApartmentIcon />}
                  />
                  <MenuListItem
                    linkTo="/administration/upload/facultetu"
                    name="оновлення факультетів"
                    icon={<AutoStoriesOutlinedIcon />}
                  />
                  <MenuListItem
                    linkTo="/administration/upload/update/users/email"
                    name="оновлення емейлів студентів"
                    icon={<MailOutlineIcon />}
                  />
                  <MenuListItem
                      linkTo="/administration/upload/data/groups"
                      name="оновлення інфи по групам"
                      icon={<MailOutlineIcon />}
                  />
                  <MenuListItem
                      linkTo="/administration/payment/edit"
                      name="Платіжні реквізити"
                      icon={<PaidOutlinedIcon />}
                  />
                  <MenuListItem
                    linkTo="/administration/upload/users/and/faculty"
                    name="оновлення факультетів та студентів"
                    icon={<AddCircleOutlineIcon />}
                  />
                  <MenuListItem
                    linkTo="/administration/log/list/"
                    name="Upload&mailing log history"
                    icon={<HistoryIcon />}
                  />

                  <MenuListItem
                    linkTo="/administration/generate/administrators"
                    name="Generate faculty administrators"
                    icon={<FormatListBulletedIcon />}
                  />
                </>
              )}
              {gotUserInfo &&
                  Object.keys(user).length > 0 &&
                  (user.user.roles === "ADMIN_FACULTY" ||
                      user.user.roles === "ADMIN_ALL_FACULTY" ||
                      user.user.roles === "ADMIN_GROUP_LEAD" ||
                      user.user.roles === 'ADMIN_DEPARTMENT' ||
                      user.user.roles === "SUPER_ADMIN") && (
                      <>
                        <MenuListItem
                            linkTo="/administration/users/list/"
                            name="Students"
                            icon={<PeopleAltOutlinedIcon />}
                        />
                        <MenuListItem
                            linkTo="/administration/upload/deactivate/studyplaces/"
                            name="Деактивація студентів"
                            icon={<ClearIcon />}
                        />
                        <MenuListItem
                            linkTo="/administration/requests/list"
                            name="Запити"
                            icon={<ChatOutlinedIcon />}
                        />
                        <MenuListItem
                            linkTo="/administration/bypass/list"
                            name="Обхідні листи"
                            icon={<ListAltIcon />}
                        />
                      </>
                  )}
              {gotUserInfo &&
                Object.keys(user).length > 0 &&
                (user.user.roles === "ADMIN_FACULTY" ||
                  user.user.roles === "ADMIN_ALL_FACULTY" ||
                  user.user.roles === "SUPER_ADMIN") && (
                  <>
                      <MenuListItem
                          linkTo="/administration/departments/list"
                          name="Відділи"
                          icon={<ApartmentIcon />}
                      />
                      <MenuListItem
                          linkTo="/administration/student/deactivator/"
                          name="Деактивація студентів"
                          icon={<ToggleOffOutlinedIcon />}
                      />
                      <MenuListItem
                        linkTo="/administration/mailing"
                        name="Розсилки"
                        icon={<ForwardToInboxRoundedIcon />}
                       />
                      <MenuListItem
                          linkTo="/administration/bypass/templates/list"
                          name="Шаблони обхідних"
                          icon={<QueuePlayNextIcon />}
                      />
                        <MenuListItem
                            linkTo="/administration/sender/bypass/"
                            name="Розсилка обхідних"
                            icon={<ForwardToInboxIcon />}
                        />
                        <MenuListItem
                          linkTo="/administration/templates/list"
                          name="Шаблони запитів"
                          icon={<SpeakerNotesOutlinedIcon />}
                        />

                        <MenuListItem
                            linkTo="/administration/faculties/list"
                            name="Посилання"
                            icon={<LinkIcon />}
                        />

                        <MenuListItem
                            linkTo="/administration/groups/list"
                            name="Групи"
                            icon={<AccountTreeIcon />}
                        />
                      </>

                )}
              <ListItem component={Link} to={"/logout/"} button={true}>
                <ListItemIcon >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItem>
            </List>
          )}
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            position: 'relative',
            height: "110vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
