import React, {useState} from "react";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import {UploadData} from "./UploadData";
import Alert from '@mui/material/Alert';
import {useTranslation} from "react-i18next";
import { Box } from '@mui/material';
import { Link } from "react-router-dom";
import fileDownload from "js-file-download";

export const DeactivateUsersStudyPlace = ()=>{
    const { t } = useTranslation();
    const download = async ( event ) =>{
            event.preventDefault();
            const result = await axiosRun(config.apiLinks.getFileExample, 'GET', {}, true );
            fileDownload(result, 'example.csv');
    }
    return <Box display={"div"} sx={{display: 'flex', flexDirection: 'column', border: 1}}>
            <Alert severity="info">{t('infoText.deactivate')}</Alert>
            <Alert severity="info">{t('infoText.facultyCodes')}</Alert>
            <Alert severity="info">{t('infoText.linkExample')}<a onClick={download} href={"#"}>{t("infoText.fileExample")}</a></Alert>
            <UploadData title={"деактивація студента на факультеті"} isAllowFacultyAdmin={true} linkUpload={config.apiLinks.uploadForDeactivate} />
    </Box>
}
